@import '../../marketplace.css';

.article {
  @media (min-width: 1231px) {
    padding-right: 50px;
  }
}

h3.title {
  font-size: 30px;
  color: var(--contrast-higher);
  margin: 0 0 15px 0;
  line-height: 0.9;

  @media (--viewportLarge) {
    font-size: 35px;
    margin: 0 0 20px 0;
  }
}

.list {
  color: inherit;
}

.list ul {
  margin: 0 0 25px 0;

  @media (--viewportLarge) {
    margin: 0 0 25px 0;
  }
}

.list ul > li {
  position: relative;
  padding-left: 20px;
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  color: var(--contrast-medium);
  margin-bottom: 10px;
  @media (--viewportLarge) {
    font-size: 18px;
  }
}

.list ul > li:before {
  content: '';
  position: absolute;
  top: 10px;
  left: 4px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: var(--primary-color);
}

.list {
  margin: 0 0 25px 0;
}

.container p {
  font-size: 16px;
  color: var(--contrast-medium);
  margin: 0 0 15px 0;
  line-height: normal;
  font-weight: 400;

  @media (--viewportLarge) {
    font-size: 18px;
  }
}
