@import '../../marketplace.css';

.artContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  background-color: #f4f4f4;

  @media (--viewportMedium) {
    padding-top: 150px;
  }
}

.artFigure {
  margin: 0;
  width: 320px;

  @media (--viewportMedium) {
    width: 517px;
  }
}

.artFigureImg {
  @media (max-width: 767px) {
    max-width: 100%;
    height: 320px;
  }
  @media (--viewportMedium) {
    width: 517px;
    height: 263px;
    object-fit: cover;
    object-position: 100% 0;
  }
}

.ltGrid {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding-bottom: 60px;

  @media (min-width: 1130px) {
    flex-direction: row;
    padding-bottom: 120px;
  }
}

.gridMargin {
  margin-top: -175px;
  @media (--viewportMedium) {
    margin-top: -112px;
  }
}

.ltGridColumn {
  flex-basis: 100%;
  width: 100%;

  @media (min-width: 1130px) {
    flex-basis: 50%;
    width: 50%;
  }
}

.ltGridFigure {
  margin: 0;
  padding: 0 24px;
  text-align: center;

  @media (min-width: 1130px) {
    padding: 0;
  }
}

.ltGridImg {
  max-width: 100%;
  height: auto;
}

.girdImgLeft {
  border-radius: 25px 25px;
  max-height: 400px;
  @media (min-width: 1130px) {
    border-radius: 0 0 30px 0;
    max-height: 1000px;
  }
}

.girdImgRight {
  border-radius: 25px 25px;
  max-height: 400px;
  @media (min-width: 1130px) {
    border-radius: 0 0 0 30px;
    max-height: 1000px;
  }
}

.columnTopFirst {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media (min-width: 1130px) {
    padding-top: 111px;
  }
}

.ltGridFirstArticle {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  background-color: white;

  @media (min-width: 1130px) {
    padding: 60px 25px 0 90px;
    align-items: flex-start;
  }
}

.ltGridArticleContent {
  padding: 0 24px;

  @media (min-width: 550px) {
    max-width: 550px;
    padding: 0;
  }
}

h2.gridTitle {
  line-height: 0.8;
  font-size: 45px;
  color: var(--contrast-higher);
  margin: 0 0 25px 0;

  @media (--viewportMedium) {
    font-size: 60px;
    margin: 0 0 30px 0;
  }
}

h2.gridTitle span {
  display: block;
}

div.ltGridArticleContent p {
  line-height: normal;
  font-size: 16px;
  color: var(--contrast-medium);
  margin: 0 0 25px 0;

  @media (--viewportMedium) {
    margin: 0 0 30px 0;
  }
}

.ltButton {
  @apply --buttonStylePrimay;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  min-height: 60px;
  border-radius: 30px;
  font-size: 13px;
}
.ltButtonSvg {
  display: inline-block;
  stroke-width: 0;
  stroke: white;
  fill: white;
  margin-left: 20px;
}

.ltGridSecondArticle {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1130px) {
    align-items: flex-end;
    justify-content: center;
    padding: 60px 90px 0 25px;
  }
}

.order1 {
  @media (max-width: 1129px) {
    order: 1 !important;
  }
}
.order2 {
  @media (max-width: 1129px) {
    order: 2 !important;
    padding-top: 60px;
  }
}
