/* @import '../../marketplace.css'; */

:global .homeCarousel {
  position: relative;
  margin-right: -230px;
  height: 519px;
}

.hcCard {
  display: block !important;
  padding: 20px 15px;
}

.hcCardBox {
  position: relative;
  display: block !important;
  width: 265px !important;
  height: 413px;
  border-radius: 15px;
  text-decoration: none;
  box-shadow: 1px 3px 10px 2px rgba(0, 0, 0, 0.35);
}

.hcCardMedia {
  width: 100%;
  min-height: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 15px;
}

.hcCardContent {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 12px;
  padding: 20px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0.7) 100%);
}

.hcCardTitle {
  color: #fff;
  line-height: 1;
  font-size: 1.375rem;
  margin: 0;
  text-shadow: 2px 2px 12px rgba(0, 0, 0, 0.6);
}
