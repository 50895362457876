@import '../../marketplace.css';

.container {
  margin-left: auto;
  margin-right: auto;
  padding: 72px 24px 40px 24px;
  flex-grow: 1;

  @media (--viewportLarge) {
    width: 763px;
    padding: 72px 48px 60px 48px;
  }
}

.title {
  font-size: 40px;
  letter-spacing: normal;
  color: var(--contrast-higher);

  @media (--viewportMedium) {
    font-size: 45px;
  }
}

.article ol {
  padding-left: 20px;

  @media (--viewportMedium) {
    padding-left: 40px;
  }
}

.article ol > li:not(:last-child) {
  margin-bottom: 32px;
}

.article h2 {
  font-size: 23px;
  margin-top: 0;
  color: var(--contrast-high);

  @media (--viewportMedium) {
    margin-bottom: 16px;
    font-size: 28px;
  }
}

.article ul {
  padding-left: 20px;
  list-style: disc;

  @media (--viewportMedium) {
    padding-left: 21px;
  }
}

.article ul > li {
  margin-bottom: 5px;
  font-size: 14px;
  color: var(--contrast-higher);
  line-height: normal;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.article p {
  font-size: 14px;
  color: var(--contrast-high);
  line-height: normal;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}
