@import '../../marketplace.css';

.section {
  padding: 60px 30px 20px 30px;

  @media (min-width: 1231px) {
    padding: 110px 0 10px 0;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1231px) {
    max-width: 1230px;
  }
}

.sectionTitle {
  font-size: 40px;
  color: var(--contrast-higher);
  line-height: 1;
  margin: 0 0 40px 0;
  min-height: 0;
  text-align: center;
  padding: 0 30px;

  @media (--viewportMedium) {
    font-size: 45px;
    margin: 0 0 50px 0;
    padding: 0 55px;
  }

  @media (min-width: 1231px) {
    text-align: center;
  }
}

.grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 30px;
  border: 1px solid #d8d8d8;

  @media (min-width: 1231px) {
    flex-direction: row;
  }
}

.gridArticle:nth-of-type(1) {
  background-image: url('/static/images/illustration-cherries.png');
  background-size: 80px;

  @media (--viewportMedium) {
    background-size: 160px;
  }
}

.gridArticle:nth-of-type(2) {
  background-image: url('/static/images/illustration-kiwi.png');
  background-size: 94px;

  @media (--viewportMedium) {
    background-size: 156px;
  }
}

.gridArticle:nth-of-type(3) {
  background-image: url('/static/images/illustration-olives.png');
  background-size: 82px;

  @media (--viewportMedium) {
    background-size: 164px;
  }
}

.gridArticle {
  position: relative;
  padding-top: 40px;
  padding-bottom: 60px;
  background-position: bottom right;

  @media (--viewportMedium) {
    padding-top: 60px;
  }

  @media (min-width: 1229px) {
    min-height: 480px;
    flex-basis: 410px;
  }
}

.articleBorder {
  border-bottom: 1px solid #d8d8d8;

  @media (min-width: 1229px) {
    border-right: 1px solid #d8d8d8;
    border-bottom: 0;
  }
}

h3.articleTitle {
  min-height: 0;
  font-size: 30px;
  color: var(--contrast-higher);
  line-height: 1;
  margin: 0 0 20px 0;
  padding: 0 30px;

  @media (--viewportMedium) {
    font-size: 32px;
    margin: 0 0 25px 0;
    padding: 0 200px 0 55px;
  }

  @media (min-width: 1231px) {
    padding: 0 55px;
  }
}

p.articleText {
  font-size: 15px;
  font-weight: 300;
  line-height: 1.4;
  margin: 0 0 12px;
  padding: 0 30px;
  color: var(--contrast-high);

  @media (--viewportMedium) {
    font-size: 17px;
    padding: 0 200px 0 55px;
    font-weight: 400;
  }

  @media (min-width: 1231px) {
    color: var(--contrast-medium);
    padding: 0 55px;
  }
}

p.articleText a {
  text-decoration: underline;
}

.articleImage {
  margin: 0;
  display: flex;
  justify-content: flex-end;
  opacity: 0.7;

  @media (max-width: 1231px) {
    opacity: 0.25;
  }
}

.memorable {
  padding: 0 24px 60px 24px;

  @media (min-width: 768px) {
    padding: 0 0 60px 0;
  }
}

.memorableContainer {
  @media (min-width: 1275px) {
    width: 1274px;
    margin: 0 auto;
  }
}

.memorableGrid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.memorableFigure {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 110px;
  margin: 0 10px;
  padding: 7px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 75px;

  @media (max-width: 300px) {
    width: 90px;
  }

  @media (min-width: 700px) {
    width: 140px;
  }

  @media (min-width: 800px) {
    width: 170px;
  }

  @media (min-width: 960px) {
    width: 190px;
  }

  @media (min-width: 1050px) {
    width: 145px;
  }

  @media (min-width: 1220px) {
    width: 170px;
  }
}

.memorableImage {
  max-width: 100px;

  @media (max-width: 300px) {
    .memorable__grid figure img {
      max-width: 85px;
    }
  }
}

.ltButton {
  @apply --buttonStylePrimay;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  min-height: 60px;
  border-radius: 30px;
  font-size: 16px;
  margin: 70px auto 0;
}

.ltButtonSvg {
  display: inline-block;
  stroke-width: 0;
  stroke: white;
  fill: white;
  margin-left: 20px;
}
