@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.dateFlex {
  display: flex;
  flex-direction: column;

  @media (min-width: 1130px) {
    align-items: center;
    flex-direction: row;
  }
}

.withinModal.dateFlex {
  display: block;
  color: inherit;
}

.priceBlock {
  display: flex;
  align-items: baseline;
  line-height: 1;
  color: white;
  margin-bottom: 20px;

  @media (min-width: 1130px) {
    padding-left: 50px;
    justify-content: center;
    margin-bottom: 0;
  }
}

.withinModal .priceBlock {
  margin-bottom: 0;
  padding-left: 0;
  color: var(--contrast-higher);
}

.priceTotalText {
  font-weight: 400;
  font-size: 14px;
}

.withinModal .priceTotalText {
  color: var(--contrast-medium);
}

.priceTotal {
  padding-left: 13px;
  font-weight: 600;
  font-size: 25px;
}

.bookingDates {
  z-index: 1;
  display: flex;
  flex-direction: column;

  @media (min-width: 1130px) {
    flex-shrink: 0;
    flex-direction: row;
  }
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  color: var(--matterColorAnti);
  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.timeSlotsError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.withinModal .priceBlockWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: 25px;
  border-top: 1px solid #e0e0e0;
}

.submitButtonWrapper {
  padding-left: 40px;

  @media (max-width: 1129px) {
    display: none;
    padding-left: 0;
    width: 270px;
  }
}

.submitButtonWrapper.isActive {
  @media (max-width: 1129px) {
    display: block;
  }
}

.withinModal .priceBlockWrapper .submitButtonWrapper {
  padding-left: 30px;
}

.submitButtonWrapper > button {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 48px;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 20px;
  width: auto;
  min-height: 48px;
  color: #2e2e2e;
  background-color: white;

  @media (max-width: 1129px) {
    width: 100%;
    justify-content: space-between;
  }
}

.withinModal .submitButtonWrapper > button {
  color: white;
  width: 146px;
  background-color: var(--primary-color);

  @media (max-width: 1129px) {
    width: 146px;
  }
}

.submitButtonWrapper > button:hover,
.submitButtonWrapper > button:focus {
  background-color: #f4f4f4;
  color: #2e2e2e;
}

.submitButtonWrapper > button > svg {
  display: inline-block;
  stroke-width: 0;
  stroke: #2e2e2e;
  fill: #2e2e2e;
  margin-left: 17px;
}

.submitButtonWrapper > button:disabled {
  opacity: 0.3;
  color: #2e2e2e;
}

.withinModal .submitButtonWrapper > button:disabled {
  opacity: 0.5;
  color: white;
  background-color: var(--primary-color);
}

.withinModal .submitButtonWrapper > button > svg {
  stroke: white;
  fill: white;
}

.withinModal .submitButtonWrapper > button:hover,
.withinModal .submitButtonWrapper > button:focus {
  background-color: #39a5a2;
  color: white;
}

.sendGiftBlock {
  margin-bottom: 20px;

  @media (min-width: 1130px) {
    display: none;
  }
}

.sendGiftBlock.isActive {
  display: none;
}

.toggleInputBtn,
.sendGiftBtn {
  @apply --buttonStylePrimay;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  min-height: 48px;
  border-radius: 24px;
  width: 100%;
  padding: 8px 20px;
  background-color: #51b1ae;
}

.sendGiftBtn:hover,
.sendGiftBtn:focus,
.sendGiftBtn:active {
  outline: 0;
  background-color: white;
  color: var(--contrast-higher);
}

.sendGiftBtn:focus svg,
.sendGiftBtn:active svg {
  fill: currentColor;
}

.inputContainer {
  @media (max-width: 1129px) {
    display: none;
  }
}

.inputContainer.isActive {
  @media (max-width: 1129px) {
    display: block;
  }
}

.toggleInputBtn {
  background-color: white;
  color: var(--contrast-higher);

  @media (min-width: 1129px) {
    display: none;
  }
}

.toggleInputBtn.isActive {
  display: none;
}

.toggleInputBtn:hover,
.toggleInputBtn:focus,
.toggleInputBtn:active {
  outline: 0;
  background-color: white;
  color: var(--contrast-higher);
}

.toggleInputBtn svg {
  fill: currentColor;
}
.toggleInputBtn:focus svg,
.toggleInputBtn:active svg {
  fill: currentColor;
}

.bookingAltLinks {
  margin-top: 20px;
  @media (max-width: 1129px) {
    margin: -10px 0 30px;
  }
}
.bookingAltLinks a {
  color: #078f8b;
  text-decoration: underline;
  font-size: 14px;
  @media (max-width: 1129px) {
    color: white;
  }
}
