@import '../../marketplace.css';

.container {
  margin-bottom: 30px;
}

h4.title {
  font-size: 20px;
  color: var(--contrast-higher);
  margin: 0 0 10px 0;
  line-height: 0.9;

  @media (--viewportLarge) {
    font-size: 25px;
    margin: 0 0 15px 0;
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}

.gridFigure {
  width: 100%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 8px;
  padding-left: 8px;
  margin: 0 0 16px;
}

.gridFigure img {
  border-radius: 15px;
  height: 165px;
  object-fit: cover;
}
