@import '../../marketplace.css';

.lstHero {
  position: relative;
  height: 100vh;
  background-color: white;

  @media (--viewportLarge) {
    height: 100vh;
  }
}

.lstHeroArtContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;

  @media (--viewportLarge) {
    height: 100vh;
  }
}

.lstHeroArtFigure {
  margin: 0;
}

.lstHeroArtFigure img {
  width: 100%;
  max-width: 100%;
  height: auto;

  @media (--viewportLarge) {
    width: 364px;
  }
}

.lstHeroContent {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: stretch;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.lstHeroContentColumn {
  position: relative;
  display: flex;
  align-items: stretch;
  flex-basis: 100%;
  max-width: 100%;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-basis: 50%;
    max-width: 50%;
  }
}

.lstHeroContentColumn:nth-child(1) {
  @media (max-width: 1023px) {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 5;
  }
}

.heroCardMedia {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.heroCardMedia div {
  @media (max-width: 1023px) {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.15) 0%,
      rgba(0, 0, 0, 0.5) 30%,
      rgba(0, 0, 0, 0.5) 70%,
      rgba(0, 0, 0, 0.15) 100%
    );
    width: 100%;
    height: 100%;
  }
}

.heroLeftContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  @media (--viewportLarge) {
    padding-left: 70px;
    align-items: flex-start;
  }
}

.heroLeftContentArticle {
  padding: 0 30px;
  @media (--viewportLarge) {
    max-width: 400px;
    padding: 0;
  }
}

.dNote {
  padding: 0 30px;
}

.dNoteCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  flex: 0 0 44px;
  border-radius: 22px;
  background-color: rgba(255, 255, 255, 0.5);

  @media (--viewportMedium) {
    background-color: #e4f2f2;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.25, 1.25);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes pulsebig {
  0% {
    transform: scale(0.9, 0.9);
  }
  50% {
    transform: scale(1.45, 1.45);
  }
  100% {
    transform: scale(0.9, 0.9);
  }
}

.dNoteCircle img {
  width: 17px;
  animation: pulsebig 1.75s ease-in-out infinite;
  @media (--viewportMedium) {
    animation: pulse 1.5s ease-in-out infinite;
  }
}

.dNoteBox {
  display: flex;
  align-items: center;
  min-height: 76px;
  padding: 0 15px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.3);

  @media (--viewportSmall) {
    border: 1px solid rgba(0, 0, 0, 0.1);
    max-width: 391px;
  }
}

.dNoteText {
  padding-left: 20px;
  color: white;
  font-size: 14px;
  line-height: normal;

  @media (--viewportLarge) {
    color: var(--contrast-high);
  }
}

.heroHost {
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 10px;
  @media (--viewportLarge) {
    color: var(--primary-color);
  }
}

ul.heroPills li {
  min-height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1px 16px;
  border-radius: 16px;
  color: white;
  font-size: 14px;
  margin-top: 18px;
  margin-right: 5px;
  background-color: rgba(255, 255, 255, 0.3);

  @media (--viewportLarge) {
    color: var(--primary-color);
    background-color: #e4f2f2;
  }
}

ul.heroPills li:hover {
  text-decoration: underline;
  cursor: pointer;
}

ul.heroPills li a {
  color: white;
  @media (--viewportLarge) {
    color: var(--primary-color);
  }
}

h1.lstHeroTitle {
  font-size: 40px;
  color: white;
  margin: 30px 0 0;
  letter-spacing: -0.1px;
  line-height: 0.9;

  @media (--viewportLarge) {
    color: var(--contrast-higher);
    font-size: 55px;
    margin: 0 0;
  }
}

h2.lstHeroSubTitle {
  font-family: Open Sans, sans-serif;
  font-size: 15px;
  color: white;
  margin: 0 0;
  line-height: normal;
  font-weight: 400;

  @media (--viewportLarge) {
    color: var(--contrast-high);
    font-size: 17px;
    margin: 0 0 20px 0;
  }
}

.lstHeroBtn {
  @apply --buttonStylePrimay;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 180px;
  min-height: 60px;
  border-radius: 30px;
  font-size: 13px;
  @media (max-width: 1023px) {
    display: none;
  }
}

.lstHeroBtn > span {
  @media (max-width: 1023px) {
    color: var(--contrast-higher);
  }
}

.lstHeroBtnSvg {
  display: inline-block;
  stroke-width: 0;
  margin-left: 20px;
  @media (max-width: 1023px) {
    stroke: var(--contrast-higher);
    fill: var(--contrast-higher);
  }
}
