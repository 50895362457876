@import '../../marketplace.css';

.container {
  margin-bottom: 30px;
}

h4.title {
  font-size: 25px;
  color: var(--contrast-higher);
  margin: 0 0 10px 0;
  line-height: 0.9;

  @media (--viewportLarge) {
    font-size: 35px;
    margin: 0 0 15px 0;
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 84px;
  padding: 15px 25px;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 15px;
}

.cardCircle {
  position: absolute;
  top: 50%;
  left: -12px;
  margin-top: -12px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: #f4f4f4;
}

.cardCircle > span {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: var(--primary-color);
}

h6.title {
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: var(--contrast-higher);
  letter-spacing: normal;
  text-transform: initial;
  line-height: 1;
  margin: 0 0 5px 0;

  @media (--viewportLarge) {
    font-size: 18px;
  }
}

.container p {
  font-size: 14px;
  font-weight: 500;
  color: var(--contrast-medium);
  line-height: 17px;
  margin: 0;
  font-style: italic;

  @media (--viewportLarge) {
    font-size: 15px;
  }
}
