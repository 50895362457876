@import '../../marketplace.css';

.container {
  @media (--viewportMedium) {
    padding-bottom: 35px;
  }

  @media (min-width: 1231px) {
    padding-right: 50px;
  }
}

.grid {
  display: flex;
  flex-direction: column;

  @media (--viewportSmall) {
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    flex-direction: row;
  }
}

.gridCol {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 30px;

  @media (--viewportSmall) {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.card {
  min-height: 68px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: white;
}

.cardOval {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 17px;
  background-color: #e4f2f2;
}

.cardOval svg {
  display: inline-block;
  stroke-width: 0;
  stroke: var(--primary-color);
  fill: var(--primary-color);
}

.cardContent {
  padding-left: 13px;
  line-height: 1;
  font-size: 16px;
}

.cardTtile {
  font-weight: 400;
  color: var(--contrast-medium);
  margin-bottom: 4px;
}

.cardText {
  font-weight: 500;
  color: var(--contrast-higher);
}
