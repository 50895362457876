@import '../../marketplace.css';

.lstDescription {
  margin-bottom: 35px;
  @media (min-width: 1231px) {
    padding-right: 50px;
  }
}

.lstDGrid {
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.lstDGridLeftColumn,
.lstDGridRightColumn {
  flex-basis: 100%;
  max-width: 100%;
}

.lstDGridLeftColumn {
  margin-bottom: 35px;

  @media (--viewportLarge) {
    flex-basis: 52%;
    max-width: 52%;
    padding-right: 20px;
    margin-bottom: 0;
  }
}

.lstDGridRightColumn {
  @media (--viewportLarge) {
    flex-basis: 48%;
    max-width: 48%;
  }
}

.lstDGridRightColumn img {
  border-radius: 15px;
  object-fit: cover;
  height: 400px;
}

h3.lstDGridTitle {
  font-size: 30px;
  color: var(--contrast-higher);
  margin: 0 0 10px 0;
  line-height: 0.9;

  @media (--viewportLarge) {
    font-size: 35px;
    margin: 0 0 15px 0;
  }
}

.lstDGridParagraph p {
  font-size: 16px;
  color: var(--contrast-medium);
  margin: 0;
  line-height: normal;
  font-weight: 400;

  @media (--viewportLarge) {
    font-size: 18px;
  }
}
