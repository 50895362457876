@import '../../marketplace.css';

.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
}

.modalBox {
  position: relative;
  top: 50%;
  left: 50%;
  max-width: 450px;
  min-height: 328px;
  border-radius: 8px;
  padding: 40px 47px;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 1045;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.3);
}
