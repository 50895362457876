@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /* Compose text label items on top of this */
  /* This stretches inline element (link) to take available vertical space (big hover area),
   * and align baselines
   */
  --TopbarDesktop_label: {
    display: inline-block;
    margin: 24px 0;
    text-decoration: inherit;

    @media (--viewportMedium) {
      margin: 24px 0;
    }
  }

  --TopbarDesktop_linkHover: {
    border-bottom: 0 solid;
    transition: var(--transitionStyleButton);

    &:hover {
      border-bottom: 4px solid var(--marketplaceColor);
      border-radius: 0;
      text-decoration: none;
    }
  }
}

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 0 0;

  /* fill */
  /*background-color: var(--matterColorLight);*/

  @media (--viewportMedium) {
    padding: 0 34px;
  }
  @media (--viewportLarge) {
    padding: 0 70px;
  }
}

/* logo */
.logoLink {
  flex-shrink: 0;
  padding: 21px 24px 21px 0;
  height: 100%;
  color: #fff;

  @media (--viewportLarge) {
    padding: 21px 36px 21px 0;
  }
}

.logoLink:hover,
.logoLink:focus {
  color: #fff;
  text-decoration: none;
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 36px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  display: inline-block;
  margin: 0 0 1px 4px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.inboxLink,
.loginLink,
.signupLink,
.profileSettingsLink,
.yourListingsLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}
.loginLink {
  margin-bottom: 20px;
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceH4FontStyles;
  font-size: 14px;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}

.menuLinks {
  text-align: right;
  flex-grow: 1;
}

.menuLinks a {
  margin-right: 32px;
  color: #fff;
  font-family: Open Sans, sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  text-shadow: 2px 2px 9px rgba(0, 0, 0, 0.8);
}

.topbarLow .menuLinks a {
  color: #555;
  text-shadow: none;
}
