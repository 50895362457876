.separator {
  margin-bottom: 35px;
}

.separator svg {
  display: inline-block;
  stroke-width: 0;
  stroke: #d8d8d8;
  fill: #d8d8d8;
}
