@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}

.heroContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;
}

.hero {
  flex-grow: 1;
  justify-content: center;
  padding-bottom: 32px;

  @media (--viewportMedium) {
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 60px;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: auto;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

/* .secHowItWorkContainer {
  padding: 80px 34px;

  @media (--viewportMedium) {
    padding: 200px 34px 200px 34px;
  }
  @media (--viewportLarge) {
    padding: 200px 70px 200px 70px;
  }
} */

.listingContainer {
  padding: 90px 34px 50px;
  @media (--viewportLarge) {
    padding: 90px 70px;
  }
}

.listingGrid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

/* A bar on top of light sections */
.section:nth-of-type(2n) .sectionContent::before {
  background: var(--marketplaceColor);
  content: '';
  display: block;
  width: 109px;
  height: 6px;

  /* Place the bar on top of .sectionContent top margin */
  position: relative;
  top: calc(-1 * var(--LandingPage_sectionMarginTop));

  @media (--viewportMedium) {
    width: 192px;
    height: 8px;
    top: calc(-1 * var(--LandingPage_sectionMarginTopMedium));
  }

  @media (--viewportLarge) {
    top: calc(-1 * var(--LandingPage_sectionMarginTopLarge));
  }
}

.mCarousel {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-wrap: nowrap;
  margin-top: -80px;

  @media (--viewportMedium) {
    display: none;
  }
}

.mCarouselContainer {
  flex-shrink: 0;
  max-width: 356px;
  overflow: hidden;
}
