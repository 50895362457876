@import '../../marketplace.css';

.root {
  display: flex;
}

.formRow {
  display: flex;
  justify-content: space-between;
}

.withinModal.formRow {
  display: block;
}

@media (min-width: 1130px) {
  .formRow:nth-child(2) {
    margin-left: 50px;
  }
}

@media (min-width: 1130px) {
  .withinModal.formRow:nth-child(2) {
    margin-left: 0;
  }
}

.field {
  position: relative;
  display: flex;
  width: 100%;

  @media (max-width: 1129px) {
    justify-content: center;
    margin-bottom: 25px;
    flex-direction: column;
  }
}

.withinModal .field {
  width: auto;

  @media (max-width: 1129px) {
    margin-bottom: 0;
  }
}

.fieldDisabled {
  background: none;
  color: --matterColorNegative;
}

.fieldDateInput {
  display: flex;
  align-items: center;

  @media (max-width: 1129px) {
    width: 270px;
    justify-content: space-between;
    flex-direction: column;
  }
}

.withinModal .fieldDateInput {
  display: block;
  width: 175px;

  @media (max-width: 1129px) {
    width: 175px;
  }
}

.fieldDateInput > label {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  font-weight: 500;
  padding: 0 15px 0 0;
  color: white;
  line-height: 1;
  font-family: 'Open Sans', sans-serif;

  @media (max-width: 1129px) {
    margin-bottom: 10px;
  }
}

.withinModal .fieldDateInput > label {
  color: #2e2e2e;
  padding: 0;
  margin-bottom: 10px;
}

.fieldSelect {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1129px) {
    width: 270px;
    flex-direction: column;
  }
}

.withinModal .fieldSelect {
  display: block;
  margin-left: 20px;
  width: 155px;
}

.fieldSelect > label {
  font-family: 'Open Sans', sans-serif;
  display: block;
  margin: 0;
  width: auto;
  font-weight: 500;
  padding: 0 15px 0 0;
  color: white;
  line-height: 1;

  @media (max-width: 1129px) {
    width: 100%;
    margin-bottom: 10px;
  }
}

.withinModal .fieldSelect > label {
  color: #2e2e2e;
  margin-bottom: 10px;
  padding: 0;
}

.fieldSelectDisabled {
  composes: fieldSelect;
}

.select {
  position: relative;
  width: 140px;
  height: 36px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 5px;
  color: white;
  line-height: 26px;
  background-color: var(--primary-color);
  background-image: url('data:image/svg+xml;utf8,<svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 3L3 0L0 3H6Z" fill="white"/><path d="M6 5L3 8L0 5H6Z" fill="white"/></svg>');
  background-size: 20px 8px;
  padding: 5px 20px 5px 20px;
  border: 1px solid white;

  @media (max-width: 1129px) {
    width: 100%;
  }
}

.withinModal .select {
  width: 155px;
  color: #2e2e2e;
  background-color: white;
  background-image: url('data:image/svg+xml;utf8,<svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 3L3 0L0 3H6Z" fill="black"/><path d="M6 5L3 8L0 5H6Z" fill="black"/></svg>');
  background-size: 20px 8px;
  padding: 5px 20px 5px 20px;
  border: 1px solid #d5d5d5;

  @media (max-width: 1129px) {
    width: 155px;
  }
}

.select:focus {
  border-color: white;
}

.withinModal .select:focus {
  border-color: #d5d5d5;
}

.selectDisabled {
  composes: select;
  background-image: url('data:image/svg+xml;utf8,<svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 3L3 0L0 3H6Z" fill="white"/><path d="M6 5L3 8L0 5H6Z" fill="white"/></svg>');
}

.rootNextMonthIcon,
.rootPreviousMonthIcon {
  stroke: var(--matterColorLight);
  fill: var(--matterColorLight);
}

/**
CSS for hiding the end date

If you want to show the field for the end date e.g. for enabling longer bookings,
you can remove the following code.
Remember to do required code changes also in FieldDateAndTimeInput.js!
**/

.field {
  width: 100%;
  display: flex;
}

.endDateHidden {
  display: none;
}

.lineBetween,
.lineBetweenDisabled {
  margin-top: 36px;
}

.lineBetweenDisabled {
  color: var(--matterColorNegative);
}

/**
CSS for hiding the end date above
**/
