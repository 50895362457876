@import '../../marketplace.css';

.pageRoot {
  display: flex;
  padding-bottom: 90px;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingNotice {
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
}

.loadingNotice img {
  width: 364px;
  animation: rotation 10s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(-15deg);
  }
  to {
    transform: rotate(344deg);
  }
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.listingPageMain {
  background-color: #f4f4f4;
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 70px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1;

  border-radius: var(--borderRadius);
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.lstContentContainer {
  padding-top: 80px;

  @media (min-width: 1231px) {
    max-width: 1230px;
    padding-top: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.lstMainGrid {
  display: flex;
  flex-direction: column;

  @media (min-width: 1231px) {
    flex-direction: row;
  }
}

.lstMain {
  flex-basis: 100%;
  padding: 0 24px;

  @media (min-width: 1231px) {
    flex-basis: 820px;
    padding: 135px 0 0 0;
    border-right: 1px solid #d8d8d8;
  }
}

.lstAside {
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 24px;

  @media (min-width: 1231px) {
    flex-basis: 410px;
    padding: 135px 0 0 50px;
  }
}

.bookingPanel {
  position: relative;
}
