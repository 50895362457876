@import '../../marketplace.css';

.container {
  padding-top: 30px;
  margin-bottom: 30px;
}

.card {
  position: relative;
  min-height: 84px;
  padding: 25px 20px;
  border-radius: 10px;
  background-color: #fff;
}

.cardCircle {
  position: absolute;
  top: -30px;
  right: 13px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background-color: #f4f4f4;
}

.cardCircleBg {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background-color: white;
}

h4.cardTitle {
  font-size: 20px;
  color: var(--contrast-higher);
  margin: 0 0 10px 0;
  line-height: 0.9;
  padding-right: 60px;

  @media (--viewportLarge) {
    font-size: 25px;
    margin: 0 0 15px 0;
  }
}

.cardListBox {
  position: relative;
  margin-bottom: 15px;
}

.listOverlay {
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 61%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.85) 94%);
  opacity: 1;
  transition: all 0.15s linear;
  z-index: 11;
}

.listOverlay.isOpen {
  opacity: 0;
  z-index: 8;
}

.cardList {
  position: relative;
  margin: 0;
  padding: 0;
  z-index: 10;
}

.cardList > li {
  position: relative;
  padding-left: 15px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  color: var(--contrast-medium);
  margin-bottom: 4px;
}

.cardList > li:before {
  content: '';
  position: absolute;
  top: 8px;
  left: 0;
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  background-color: var(--primary-color);
}

.toggleBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1px 16px;
  border-radius: 16px;
  color: var(--primary-color);
  text-transform: uppercase;
  font-size: 13px;
  border: 1px solid #e4f2f2;
  background-color: #e4f2f2;
  cursor: pointer;
}

.toggleBtn:focus,
.toggleBtn:active,
.toggleBtn:active:focus {
  outline: 0;
  background-color: #d3eaea;
  border: 1px solid #d3eaea;
}

.toggleBtn svg {
  display: inline-block;
  stroke-width: 0;
  stroke: var(--primary-color);
  fill: var(--primary-color);
  margin-left: 5px;
  transform: rotate(-180deg);
  transition: transform 0.15s linear;
}

.toggleBtn.isOpen svg {
  transform: rotate(0deg);
}
