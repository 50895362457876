@import '../../marketplace.css';

.nsSection {
  position: relative;
  padding: 140px 10px 160px 10px;
  background-color: #078f8b;

  @media (--viewportMedium) {
    padding: 162px 0 213px 0;
  }
}

.nsArtTopRight {
  position: absolute;
  top: 0;
  right: 0;
  width: 217px;
  overflow: hidden;

  @media (--viewportSmall) {
    width: 325px;
  }
}

.nsArtTopRight img {
  max-width: 325px;
  height: auto;
}

.nsArtBottomLeft {
  position: absolute;
  bottom: 0;
  left: 0;
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.nsArtBottomLeft img {
  max-width: 335px;
}

.nsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nsFormContent {
  flex-basis: 100%;
  max-width: 100%;
  padding-left: 30px;
  padding-right: 30px;

  @media (min-width: 654px) {
    padding: 0;
    flex-basis: 653px;
    max-width: 653px;
  }
}

.nsFormContent h3 {
  text-align: center;
  line-height: 0.8;
  font-size: 45px;
  color: #fff;
  margin: 0 0 20px 0;

  @media (--viewportMedium) {
    font-size: 60px;
    margin: 0 0 25px 0;
  }
}

.nsArticle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nsArticle a {
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
}

.nsArticle p {
  text-align: center;
  line-height: normal;
  font-size: 16px;
  color: #fff;
  margin: 0 0 20px 0;
  max-width: 320px;

  @media (--viewportMedium) {
    font-size: 18px;
    margin: 0 0 25px 0;
    max-width: 370px;
  }
}
