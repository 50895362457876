@import '../../marketplace.css';

.widget {
  display: inline-block;
  color: var(--contrast-medium);
  margin-right: 25px;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 135px;
  height: 135px;
  border-radius: 50%;
  border: 1px solid #c3c3c3;
}

.circle img {
  mix-blend-mode: darken;
  max-width: 90px;
  max-height: 65px;
}

.text {
  margin: -33px 0 13px -5px;
  padding-top: 7px;
  width: 98px;
  min-height: 57px;
  line-height: 17px;
  font-size: 14px;
  color: var(--contrast-higher);
  background-color: #f4f4f4;
}
