@import '../../marketplace.css';

.bar {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 1030;
  padding-bottom: 20px;
  transition-property: bottom;
  transition: bottom 400ms ease-out 1s;

  @media (min-width: 1130px) {
    padding: 0;
    box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.1);
  }
}

.overlapHero .bar {
  transition-delay: 0s;

  @media (max-width: 1130px) {
    bottom: -100px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  margin: 0 auto;
  width: 314px;
  background-color: var(--primary-color);

  @media (min-width: 1130px) {
    height: 65px;
    padding: 0 70px;
    justify-content: space-between;
    flex-direction: row;
    border-radius: 0;
    width: 100%;
  }
}

.barOuter {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  margin: 0 auto;
  width: 314px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.13);
  background-color: white;

  @media (min-width: 1130px) {
    justify-content: space-between;
    flex-direction: row;
    border-radius: 0;
    width: 100%;
    box-shadow: none;
  }
}

.barOuter.isActive {
  @media (max-width: 1130px) {
    box-shadow: 0 0 7px 1000px rgba(0, 0, 0, 0.6);
  }
}

.leftColumnTitle {
  font-weight: 500;
  line-height: 1;
  color: white;
  font-size: 18px;
  display: none;
  margin-bottom: 15px;
  width: 262px;
  text-align: right;

  @media (min-width: 1130px) {
    font-size: 20px;
    margin-bottom: 0;
    width: 100%;
    display: block;
  }
}

.columnWrapper {
  width: 100%;
  padding: 0 20px;

  @media (min-width: 1130px) {
    display: none;
  }
}

.rightColumn {
  display: none;

  @media (min-width: 1130px) {
    display: block;
  }
}

@media (max-width: 1129px) {
  .rightColumn.isActive {
    display: block;
  }
}

.mobileBtn {
  @apply --buttonStylePrimay;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  min-height: 60px;
  border-radius: 30px;
  width: 100%;
  padding: 8px 19px 8px 19px;

  @media (min-width: 1130px) {
    display: none;
  }
}

.mobileBtn:focus,
.mobileBtn:active {
  outline: 0;
  background-color: var(--primary-color);
}

.mobileBtnText {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
  color: white;
  margin: 0 auto;
}

.mobileBtnSvg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: white;
}

.mobileBtn.isActive .mobileBtnSvg svg {
  transform: rotate(180deg);
}

.mobileSpaceBottom,
.mobileSpaceTop {
  display: none;
}

@media (max-width: 1129px) {
  .mobileSpaceTop.isActive {
    display: block;
    width: 100%;
    height: 15px;
  }
}

@media (max-width: 1129px) {
  .mobileSpaceBottom.isActive {
    display: block;
    width: 100%;
    height: 27px;
  }
}

.barAction {
  display: none;

  @media (min-width: 1130px) {
    display: flex;
    align-items: center;
  }
}

.priceTitle {
  align-items: baseline;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  font-weight: 400;
  display: none;

  @media (min-width: 1130px) {
    display: flex;
  }
}

.priceTitle strong {
  color: white;
  padding-left: 10px;
  display: inline-block;
  font-size: 25px;
  font-weight: 500;
}

.bookButton,
.giftButton {
  @apply --buttonStylePrimay;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  border-radius: 30px;
  padding: 8px 19px 8px 19px;
  background-color: white;
  color: var(--contrast-higher);
}

.bookButton {
  &:focus {
    outline: 0;
  }
}

.buttonGroup {
  margin-left: 50px;
  display: flex;
  align-items: center;
}

.buttonGroup svg {
  margin-left: 20px;
}

.or {
  color: white;
  font-size: 15px;
  font-weight: 400;
  width: 62px;
  text-align: center;
}

.modalCloseButton {
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid transparent;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  line-height: 50px;
  text-align: center;
  color: white;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  right: -70px;
  cursor: pointer;
}

.modalTitle {
  margin: 0 0 25px;
  font-size: 40px;
  text-align: center;
}

.modalCloseButton:focus,
.modalCloseButton:focus {
  outline: 0;
}

.basicModal {
  display: none;

  @media (min-width: 1130px) {
    display: block;
  }
}
