@import '../../marketplace.css';

.container {
  @media (--viewportMedium) {
    margin-bottom: 35px;
  }
}

.grid {
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
    padding-right: 50px;
  }
}

.gridContent,
.gridImage {
  flex-basis: 100%;
  max-width: 100%;
}

.gridImage {
  margin: 0 0 35px 0;

  @media (--viewportLarge) {
    flex-basis: 345px;
    max-width: 345px;
    margin: 0;
  }
}

.gridImage img {
  border-radius: 10px;
  height: 483px;
  object-fit: cover;
}

.gridContent {
  @media (--viewportLarge) {
    flex-basis: calc(100% - 345px);
    max-width: calc(100% - 345px);
    padding-left: 45px;
  }
}

h3.title {
  font-size: 30px;
  color: var(--contrast-higher);
  margin: 0 0 10px 0;
  line-height: 0.9;

  @media (--viewportLarge) {
    font-size: 35px;
    margin: 0 0 15px 0;
  }
}

.gridContent p {
  font-size: 16px;
  color: var(--contrast-medium);
  margin: 0 0 25px 0;
  line-height: normal;
  font-weight: 400;

  @media (--viewportLarge) {
    font-size: 18px;
    margin: 0 0 25px 0;
  }
}

.accolades {
  display: flex;
}
