@import '../../marketplace.css';

.pageTitle {
  text-align: center;
  margin-bottom: 0;
  @media (--viewportMedium) {
    text-align: left;
  }
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 70px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.coverImage {
  width: 100%;
  height: 528px;
  border-radius: 4px;
  object-fit: cover;
  margin: 32px 0 40px;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentSide {
  font-style: italic;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
  }
}

.contentMain {
  width: 90%;

  @media (--viewportMedium) {
    max-width: 650px;
  }
}

h2.sectionTitle {
  font-size: 40px;
  color: var(--contrast-higher);
  line-height: 1;
  margin: 40px 0 10px 0;
  min-height: 0;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 45px;
    text-align: left;
  }
}

.teamMember {
  margin-top: 26px;
}
.teamMember img {
  width: 100%;
  margin-bottom: 10px;

  @media (--viewportMedium) {
    width: 240px;
    height: 240px;
    float: right;
    margin: 0 0 10px 20px;
  }
}
