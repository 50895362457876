@import '../../marketplace.css';

.container {
  position: relative;
  padding: 120px 24px 120px 24px;
  background-color: var(--primary-color);

  @media (--viewportMedium) {
    padding: 162px 0 213px 0;
    min-height: 610px;
  }
}

.rightArt,
.leftArt {
  position: absolute;
  opacity: 0.3;
  mix-blend-mode: screen;
  @media (--viewportMedium) {
    opacity: 0.4;
  }
}

.rightArt {
  top: 0;
  right: 0;
  width: 162px;
  height: 157px;
  @media (--viewportMedium) {
    width: 325px;
    height: 315px;
  }
}

.leftArt {
  bottom: 0;
  left: 0;
  width: 167px;
  height: 140px;
  @media (--viewportMedium) {
    width: 335px;
    height: 280px;
  }
}

.grid {
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (--viewportSmall) {
    justify-content: center;
    flex-direction: row;
  }
}

.content {
  position: relative;
  flex-basis: 100%;
  max-width: 100%;
  /* z-index: 10; */

  @media (min-width: 760px) {
    flex-basis: 758px;
    max-width: 758px;
  }
}

.content h4 {
  text-align: center;
  line-height: 1;
  font-size: 35px;
  color: white;
  margin: 0 0 30px 0;

  @media (--viewportMedium) {
    font-size: 60px;
  }
}

.btnGridCol {
  padding: 0 10px;

  @media (max-width: 549px) {
    padding: 0;
    flex-basis: 100%;
    width: 100%;
  }
}

.btnGridCol:nth-child(1) {
  @media (max-width: 549px) {
    margin-bottom: 25px;
  }
}

.btn {
  @apply --buttonStylePrimay;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 160px;
  min-height: 60px;
  border-radius: 30px;
  font-size: 13px;
  color: var(--contrast-higher);
  background-color: white;

  @media (max-width: 549px) {
    width: 100%;
    justify-content: space-between;
    padding: 8px 35px;
    font-size: 14px;
  }

  &:focus {
    outline: 0;
  }
}

.btnSvg {
  display: inline-block;
  stroke-width: 0;
  stroke: var(--contrast-higher);
  fill: var(--contrast-higher);
  margin-left: 17px;
}

.btn:hover .btnSvg,
.btn:focus .btnSvg {
  stroke: white;
  fill: white;
}
